$primary: #9c93bb;
$primary-hover: #736c8a;

.ant-card {
  box-shadow: darkgray 1px -1px 6px;
}

.ant-pagination-item-active {
  color: #fff;
  background-color: $primary !important;
}

.ant-btn-primary {
  background-color: $primary;
  border-color: $primary;

  &:hover {
    background-color: $primary-hover !important;
    border-color: $primary;
  }
  &:active {
    background-color: $primary-hover !important;
    border-color: $primary;
  }
  &:focus {
    background-color: $primary-hover !important;
    border-color: $primary;
  }
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $primary-hover !important;
}

// .ant-steps-item-tail {
//   background-color: $primary !important;
// }

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot, .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: $primary !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  background-color: $primary !important;
}


.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: $primary !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $primary !important;
}

// .ant-steps-icon-dot {
//   background-color: $primary !important;
// }

.button--purple {
  background-color: var(--purple);
  color: white !important;

  &:hover {
    background-color: #593699 !important;
  }
  &:active {
    background-color: #6f42c1 !important;
  }
  &:focus {
    background-color: #6f42c1 !important;
  }
}

.button--primary {
  background-color: var(--blue);
  color: white !important;

  &:hover {
    background-color: #0068d6 !important;
  }
  &:active {
    background-color: #0068d6 !important;
  }
  &:focus {
    background-color: #0068d6 !important;
  }
}

.button--orange {
  background-color: var(--orange);
  color: white !important;
  &:hover {
    background-color: #e97414 !important;
  }
  &:active {
    background-color: #e97414 !important;
  }
  &:focus {
    background-color: #e97414 !important;
  }
}