.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

h1 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: lighter;
  margin: 1rem 0 1rem 0;
}

.loading {
  font-size: 100;
  left: 0;
  right: 0;
  top: '40%';
  position:'absolute';
}